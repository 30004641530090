import React from 'react';
import { Spoiler, Table, List, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import team1 from '../../assets/images/team-1-1.jpg';
import akinmola from '../../assets/images/departments/clinical-path/akinmola.png';
import azinge from '../../assets/images/departments/clinical-path/azinge.png';
import lafenwa from '../../assets/images/departments/clinical-path/lafenwa.png';
import soriyan from '../../assets/images/departments/clinical-path/soriyan.png';
import abolaji from '../../assets/images/departments/clinical-path/abolaji.png';
import awoniyi from '../../assets/images/departments/clinical-path/awoniyi.png';
import benahi from '../../assets/images/departments/clinical-path/benahi.png';
import emmanuel from '../../assets/images/departments/clinical-path/emmanuel.png';
import gloria from '../../assets/images/departments/clinical-path/gloria.png';
import ikona from '../../assets/images/departments/clinical-path/ikona.png';
import kehinde from '../../assets/images/departments/clinical-path/kehinde.png';
import kelechi from '../../assets/images/departments/clinical-path/kelechi.png';
import monsiru from '../../assets/images/departments/clinical-path/monsiru.png';
import comfort from '../../assets/images/departments/clinical-path/comfort.png';
import nwanyiaru from '../../assets/images/departments/clinical-path/nwanyiaru.png';
import odunayo from '../../assets/images/departments/clinical-path/odunayo.png';
import ogechi from '../../assets/images/departments/clinical-path/ogechi.png';
import olasunkanmi from '../../assets/images/departments/clinical-path/olasunkanmi.png';
import olatunbosun from '../../assets/images/departments/clinical-path/olatunbosun.png';
import olukemi from '../../assets/images/departments/clinical-path/olukemi.png';
import oluwadare from '../../assets/images/departments/clinical-path/oluwadare.png';
import omobolanle from '../../assets/images/departments/clinical-path/omobolanle.png';
import quadri from '../../assets/images/departments/clinical-path/quadri.jpg';
import omotunde from '../../assets/images/departments/clinical-path/omotunde.png';

const ClinicalPath = () => {
  const units = [
    {
      name: 'Routine clinical chemistry',
    },
    {
      name: 'Endocrinology',
    },
    {
      name: 'Therapeutic drug monitoring',
    },
    {
      name: 'Toxicology',
    },
    {
      name: 'Tumor markers',
    },
    {
      name: 'Molecular diagnostics',
    },
    {
      name: 'Human Nutrition (Trace metals, antioxidants, vitamins, etc.)',
    },
    {
      name: 'Electrophoresis',
    },
    {
      name: 'Osmometry',
    },
    {
      name: 'High-performance liquid chromatography/Mass spectrometry',
    },
    {
      name: 'Immunology',
    },
    {
      name: 'Quality Management System',
    },
    {
      name: 'Research Support',
    },
  ];
  const others = [
    {
      name: 'Dr. Ighedosa Evelyn',
      designation: 'Registrar',
      image: benahi,
    },
    {
      name: 'Dr. Kalu Kenneth',
      designation: 'Registrar',
      image: kelechi,
    },
    {
      name: 'Mr. Ibang Bassey',
      designation: 'Deputy Director Laboratory Services',
      image: ikona,
    },
    {
      name: 'Mr Adegoke Kehinde',
      designation: 'Deputy Director Laboratory Services',
      image: monsiru,
    },
    {
      name: 'Mr. Okoli Daniel',
      designation: 'Assistant Director Laboratory Services',
      image: team1,
    },
    {
      name: 'Mr Adejare Adejimi',
      designation: 'Assistant Director Laboratory Services',
      image: emmanuel,
    },
    {
      name: 'Mr Ogunowo Sunday',
      designation: 'Assistant Director Laboratory Services',
      image: awoniyi,
    },
    {
      name: 'Mrs Abioye Abimbola',
      designation: 'Assistant Director Laboratory Services',
      image: odunayo,
    },
    {
      name: 'Mrs Vanlare Temitope',
      designation: 'Chief Medical Laboratory Scientist',
      image: olukemi,
    },
    {
      name: 'Mrs Osobukola Nneka',
      designation: 'Principal Medical Laboratory Scientist',
      image: nwanyiaru,
    },
    {
      name: 'Mrs Duruewuru Maryassumpta',
      designation: 'Principal Medical Laboratory Scientist',
      image: team1,
    },
    {
      name: 'Mr Adebisi Wasiu',
      designation: 'Medical Laboratory Scientist',
      image: team1,
    },
    {
      name: 'Mrs Adetuyi Olufunke',
      designation: 'Chief Medical Laboratory Technician',
      image: comfort,
    },
    {
      name: 'Mr Soneye Omotayo',
      designation: 'Chief Medical Laboratory Technician',
      image: quadri,
    },
    {
      name: 'Mr Laokenu Abiodun',
      designation: 'Principal Medical Laboratory Technician',
      image: team1,
    },
    {
      name: 'Mr Akinpelu Sikiru',
      designation: 'Principal Medical Laboratory Technician',
      image: team1,
    },
    {
      name: 'Mrs Adekoya Omolola',
      designation: 'Senior Medical Laboratory Technician',
      image: team1,
    },
    {
      name: 'Mrs Etokudoh Esther',
      designation: 'Medical Laboratory Technician',
      image: team1,
    },
    {
      name: 'Mrs Amaechi Ebele',
      designation: 'Assistant Chief Store Officer',
      image: gloria,
    },
    {
      name: 'Mrs Adesanya Olajumoke',
      designation: 'Senior Executive Officer',
      image: omobolanle,
    },
    {
      name: 'Mrs Adio Olajumoke',
      designation: 'Senior Executive Officer',
      image: omotunde,
    },
    {
      name: 'Mrs Fatimehin Christiana',
      designation: 'Higher Executive Officer',
      image: team1,
    },
    {
      name: 'Mr Illori Gbenga',
      designation: 'Chief Clerical Officer',
      image: olasunkanmi,
    },
    {
      name: 'Mr Atiku Saheed',
      designation: 'Senior Clerical Officer',
      image: team1,
    },
    {
      name: 'Mrs Ige Bukola',
      designation: 'Senior Data Processing Assistant I',
      image: team1,
    },
    {
      name: 'Mr Renner Akinbami',
      designation: 'Senior Health Assistant',
      image: team1,
    },
    {
      name: 'Miss Ibrahim Odunayo',
      designation: 'Senior Health Assistant',
      image: kehinde,
    },
    {
      name: 'Miss Fadipe Abiodun',
      designation: 'Senior Clerical Officer',
      image: team1,
    },
    {
      name: 'Mrs Faseun Kuburat',
      designation: 'Senior Data Processing Assistant II',
      image: team1,
    },
    {
      name: 'Mr Saka Ifeoluwa',
      designation: 'Higher Health Assistant',
      image: team1,
    },
    {
      name: 'Miss Atunramu Imole',
      designation: 'Clerical Officer II',
      image: team1,
    },
    {
      name: 'Miss Agammegwa Mary-Gemma',
      designation: 'Data Processing Assistant I',
      image: ogechi,
    },
    {
      name: 'Mr Onigbanjo Ibrahim',
      designation: 'Health Assistant',
      image: abolaji,
    },
    {
      name: 'Mr Owolabi Lawal',
      designation: 'Health Assistant',
      image: team1,
    },
    {
      name: 'Mrs Usman Tawakalitu',
      designation: 'Porter',
      image: olatunbosun,
    },
    {
      name: 'Mr Fasanmade Adesola',
      designation: 'Locum Assistant Data Processing Officer',
      image: oluwadare,
    },
  ];

  return (
    <Layout pageTitle='Department of Clinical Pathology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Clinical Pathology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Clinical Pathology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Clinical Pathology Department provides routine and
                        specialised Chemical Pathology Laboratory Services and
                        outpatient clinical services for metabolic disorders
                        (such as obesity, diabetes mellitus, dyslipidemias,
                        etc.). The Department also receives in-patients
                        consultations for metabolic disorders and performs
                        dynamic function testing for the investigation of
                        Endocrine disorders. The Department offers 24/7
                        emergency and routine laboratory services, clinical
                        services, training, and research support in Laboratory
                        medicine. These services are provided in our central
                        hospital laboratory in Idi Araba, Yaba annex
                        laboratories (Dermatology and Psychiatry outpatient
                        clinics), accident and emergency laboratory, metabolic
                        clinic, and clinical consults when requested (from
                        various units and wards in the hospital).
                        <br />
                        <br /> The Department provides accessible and affordable
                        comprehensive diagnostic, training, clinical, and
                        research support services to LUTH patients, other
                        hospitals (public, corporate and private, clinicians,
                        students at all levels), and researchers in all fields
                        of medicine. The laboratory is an accredited center for
                        undergraduate training of medical students, medical
                        laboratory science students, and postgraduate residency
                        (specialisation) training in Chemical Pathology for
                        resident doctors. <br />
                        <br /> In Addition to clinical, analytical, and training
                        services, the Department provides an electronic result
                        reporting service on our Cloud-based laboratory
                        information system (LIS), making our results accessible
                        to our clients online and shortening the turnaround time
                        of results. The laboratory provides advice on
                        appropriate investigations and interpretative comments
                        on all laboratory results 24/7 via the Cloud-based LIS.{' '}
                        <br />
                        <br /> Dr. Oyetunji O. Soriyan is the current Head of
                        the Department.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <h2 className='blog-one__title' style={{ marginTop: 2 }}>
                      Organisation and Scope of Services
                    </h2>
                    <p className='course-details__tab-text'>
                      The laboratory provides a steadily increasing range of
                      diagnostic clinical biochemistry services. Currently, it
                      offers basic general chemistry studies of liver and renal
                      function, endocrine function tests for assessments of
                      thyroid, reproductive dysfunction and other
                      endocrinopathies. It can assess metabolic disorders like
                      dyslipidemias and diabetes mellitus and determine tumour
                      marker levels for common cancers.
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Laboratory Units
                      </h2>
                      <List
                        spacing='xs'
                        size='sm'
                        center
                        icon={
                          <ThemeIcon color='teal' size={24} radius='xl'>
                            <CircleCheck size={16} />
                          </ThemeIcon>
                        }
                      >
                        {units.map((data) => (
                          <List.Item key={data.name}>{data.name}</List.Item>
                        ))}
                      </List>
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Key services offered by the Department/Unit
                      </h2>
                      1. Clinical Services: Obesity and metabolic outpatient
                      clinic on Mondays, Clinical liaison/consult with other{' '}
                      <span style={{ paddingLeft: 13 }}>
                        Clinical Departments.
                      </span>
                      <br />
                      2. 24/7 Emergency and routine laboratory services: - via
                      our various Laboratories units:- <br />
                      <span style={{ paddingLeft: 20 }}>
                        i. Clinical Chemistry Laboratory – Electrolytes, Urea,
                        Creatinine, Lipogram,Liver Function Test e.t.c.
                      </span>
                      <br />
                      <span style={{ paddingLeft: 20 }}>
                        ii. Endocrinology (hormonal assays) and immunochemistry
                        of tumor markers and other biomarkers
                      </span>
                      <br />
                      <span style={{ paddingLeft: 20 }}>
                        iii. Toxicology Laboratory – Screening for Substance of
                        Abuse
                      </span>{' '}
                      <br />
                      <span style={{ paddingLeft: 20 }}>
                        iv. Special Testing Laboratory – Osmometry,
                        Electrophoresis
                      </span>{' '}
                      <br />
                      <span style={{ paddingLeft: 20 }}>
                        v. Laboratory quality management system (e.g. method
                        validation and verification services, etc)
                      </span>{' '}
                      <br />
                      <span style={{ paddingLeft: 20 }}>
                        vi. Research support for various Research groups in the
                        hospital and the College of Medicine
                      </span>
                      <br />
                    </p>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={azinge} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Elaine C. Azinge </h3>
                          <p>
                            <span className='detail-head'>Profile:</span>{' '}
                            Professor of Chemical Pathology & Head, Clinical
                            Pathology Department, LUTH with a special interest
                            in Human Nutrition and General Clinical Chemistry.
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS (Lagos), M.Sc, FMCPath, FWACP
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Chemical Pathology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={soriyan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. Oyetunji O. Soriyan</h3>
                          <p>
                            <span className='detail-head'>Profile:</span> Senior
                            Lecturer & Consultant Chemical Pathologist. Area of
                            Special interest includes; Tumor markers, Laboratory
                            Quality Management System, Endocrinology, and Method
                            Validation.
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS (Ibadan), MWACP (Lab. Med), FMCPath,
                            Certificate, Laboratory Quality Management System
                            (Clinical Laboratory Standards Institute, USA)
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Chemical Pathology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinmola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. Olukayode O Akinmola</h3>
                          <p>
                            <span className='detail-head'>Profile:</span>{' '}
                            Consultant with a special interest in general
                            clinical chemistry
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS(Ibadan), FMCPath
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Chemical Pathology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={lafenwa} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>DR. Ademola David Lafenwa</h3>
                          <p>
                            <span className='detail-head'>Profile:</span>{' '}
                            Consultant with a particular interest in general
                            clinical chemistry
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS(Lagos), FMCPath
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Chemical Pathology
                          </p>
                        </div>
                      </div>
                      {others.map((ele, index) => (
                        <div key={index} className='blog-details__author'>
                          <div className='blog-details__author-image'>
                            <img src={ele.image} alt='profile person' />
                          </div>
                          <div className='blog-details__author-content'>
                            <h3>{ele.name}</h3>
                            <p>
                              <span className='detail-head'>Designation:</span>{' '}
                              {ele.designation}
                            </p>
                          </div>
                        </div>
                      ))}

                      <h2 className='blog-one__title' style={{ marginTop: 50 }}>
                        Laboratory Personnel
                      </h2>
                      <p className='course-details__tab-text'>
                        CONSULTANT GRADE MEDICAL PERSONNELS: The Consultants in
                        the Department provide oversight over the activities of
                        the Department's various units. They provide direction
                        for test selection and, where appropriate, provide
                        interpretative comments. They are directly responsible
                        for the training of resident doctors.
                        <br />
                        <br />
                        RESIDENTS IN CHEMICAL PATHOLOGY: The Department is
                        accredited for providing specialist training for
                        candidates seeking to obtain Fellowships from the
                        National Postgraduate Medical College and the West
                        African College of Physicians (Laboratory Medicine). As
                        such, Resident doctors acquire skills in the discipline
                        through hands-on experiences in the laboratory and
                        structured theoretical sessions with the consultants.
                        <br />
                        <br />
                        MEDICAL LABORATORY SCIENTISTS: They are responsible for
                        performing laboratory tests analysis. The Department has
                        experienced and highly competent medical laboratory
                        scientists who provide quality laboratory service
                        delivery.
                        <br />
                        <br />
                        OTHER SUPPORT STAFF: The Department has competent and
                        dedicated medical laboratory technicians, administrative
                        and clerical staff, store officers, data processing and
                        IT officers, health assistants, porters, etc.
                      </p>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:clinpath@luth.gov.ng'>                        
                        clinpath@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default ClinicalPath;
